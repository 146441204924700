<template>
  <main class="container categories">
    <section
      v-if="defaultCategory?.banner_photo"
      :style="{
        background: 'url(' + defaultCategory?.banner_photo + ')',
      }"
      class="categories_banner"
      style="position: relative"
    >
      <div style="z-index: 2" class="c_banner_text">
        <p v-html="defaultCategory?.description[localeLang]"> </p>
        <button
          v-show="defaultCategory?.banner_button_visible"
          class="banner_btn"
          :style="{ background: `${defaultCategory?.banner_button_color}` }"
          ><a
            :style="{
              color: `${defaultCategory?.banner_button_text_color}`,
            }"
            target="_blank"
            :href="defaultCategory?.banner_button_link"
            >{{ defaultCategory?.banner_button_text[localeLang] }}</a
          >
        </button>
      </div>
      <img
        style="position: absolute"
        :src="defaultCategory?.banner_photo"
        :alt="getImageName(defaultCategory?.banner_photo)" :title="getImageName(defaultCategory?.banner_photo)" 

      />
    </section>

    <section class="d-flex">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" sm="3" class="block_left">
            <h4 class="p17">Filter by</h4>
            <aside class="nav-bar">
              <div class="nav_close">
                <span class="icon_close_1"></span>
                <span class="icon_close_2"></span>
              </div>

              <div class="cat-">
                <ul
                  class="filter_header pa-0"
                  :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                >
                  <li
                    v-for="categoryItem in retailCategories"
                    :key="categoryItem?.id"
                    :class="{
                      active: $route.params.slug === categoryItem.url,
                    }"
                    class="d-flex align-center f_items nav__link-dropdown"
                  >
                    <span
                      class="nav__link"
                      @click.stop="
                        setCategories(
                          categoryItem.title[localeLang],
                          categoryItem,
                          categoryItem.id
                        )
                      "
                      >{{ categoryItem.title.ru }}</span
                    >
                    <CategoryRecursion
                      @select="
                        (item) => {
                          setCategories(item.title[localeLang], item, item.id)
                        }
                      "
                      :array="categoryItem.childrens"
                    />
                  </li>
                </ul>
              </div>
               <v-expansion-panels
                style="width: 88%; border: 1px solid #e4f1e3; margin-top: 10px;"
                flat
                class="mb-1"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Colors
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                      <div
                        v-for="(color, index) in colors"
                        :key="index"
                        @click="filterByColor(color?.id)"
                        style="cursor: pointer"
                      >
                        <v-badge
                          dot
                          inline
                          :color="color.colorCode"
                          class="mr-3"
                          :class="{ bordered: color.title === 'White' }"
                        ></v-badge>
                        <span>{{ color.title }}</span>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                class="mb-1"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Size
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container class="pa-0 size-filter" fluid>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input
                              class="size_choice mr-3"
                              type="checkbox"
                            /><span>One-size</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XS</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>S</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>M</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>L</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XL</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
              <!-- <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Price
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-range-slider
                      color="#84C897"
                      v-model="value"
                      track-color="'#E8E8E8'"
                      thumb-label="always"
                      hide-details
                    ></v-range-slider>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
            </aside>
          </v-col>
          <v-col
            sm="9"
            class="block_right"
            :class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex nav__menu"
                :class="[
                  $vuetify.breakpoint.smAndDown
                    ? 'flex-column'
                    : 'justify-space-between',
                ]"
              >
                <!-- <v-treeview
                    dense
                    open-all
                    v-if="retailCategories[0].children?.length"
                    :items="retailCategories"
                    activatable
                    selection-type="independent"
                    return-object
                    item-children="childrens"
                  >
                    <template v-slot:label="{ item }">
                      <div
                        v-if="item?.children?.length"
                        @click="
                          setCategories(item.title[localeLang], item, item.id)
                        "
                        class="v-treeview-node__label"
                        >{{ item.title.ru }}</div
                      >
                    </template>
                  </v-treeview> -->
                <!-- <ul class="pa-0">
                    <li
                      v-for="category in retailCategories"
                      :key="category.id"
                      class="nav__link-dropdown"
                    >
                      {{ category.title.ru }}
                    </li>
                  </ul> -->
                <!-- <div v-if="$vuetify.breakpoint.smAndDown"> -->
                <!-- <ul
                  class="filter_header pa-0"
                  :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                >
                  <li
                    v-for="categoryItem in retailCategories"
                    :key="categoryItem?.id"
                    :class="{
                      active: $route.params.slug === categoryItem.url,
                    }"
                    class="d-flex align-center f_items nav__link-dropdown"
                  >
                    <span
                      class="nav__link"
                      @click.stop="
                        setCategories(
                          categoryItem.title[localeLang],
                          categoryItem,
                          categoryItem.id
                        )
                      "
                      >{{ categoryItem.title.ru }}</span
                    >
                    <CategoryRecursion
                      @select="
                        (item) => {
                          setCategories(item.title[localeLang], item, item.id)
                        }
                      "
                      :array="categoryItem.childrens"
                    />
                  </li>
                </ul> -->
                <!-- </div> -->

                <div>
                  <div
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="mb-2 d-flex justify-space-between"
                  >
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Colors</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div v-for="(color, index) in colors" :key="index">
                          <v-badge
                            dot
                            inline
                            :color="color.colorCode"
                            class="mr-3"
                            :class="{ bordered: color.title === 'White' }"
                          ></v-badge>
                          <span>{{ color.title }}</span>
                        </div>
                      </v-card>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Size</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-container class="pa-0" fluid>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              one size
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              xs
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              s
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              m
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              l
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              xl
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Price</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <div class="pt-5">
                        <v-range-slider
                          color="#84C897"
                          v-model="value"
                          track-color="'#E8E8E8'"
                          thumb-label="always"
                          hide-details
                          :thumb-size="15"
                        ></v-range-slider>
                      </div>
                    </v-menu>
                  </div>
                  <div class="sort">
                    <!-- <span class="sort_by">Sort by:</span> -->
                    <!-- <v-menu bottom offset-y content-class="sort__menu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :elevation="0"
                          style="border: 1px solid #f2f2f2; height: unset"
                          class="px-0"
                        >
                          <span class="mr-5">{{ sortedBy }}</span>
                          <span class="arrow_bottom"></span>
                        </v-btn>
                      </template>
                      <ul class="filter__dropdown-menu d-block pa-0">
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                      </ul>
                    </v-menu> -->
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="first_banners">
              <div
                v-for="banner in mainCategoryBanners"
                :key="banner.id"
                class="banner"
              >
                <a :href="banner.link">
                  <div
                    class="banner__img"
                    :style="{
                      background: `url(${banner.photo}) no-repeat`,
                      backgroundSize: 'cover',
                    }"
                  ></div>
                  <span class="banner__title">{{ banner.name }}</span>
                </a>
              </div>
            </div>
            <div class="products">
              <transition-group name="slide-fade" mode="out-in" tag="div">
                <v-row key="recommended" class="list_product mx-n3">
                  <h5>Recommended For You</h5>
                  <v-col
                    v-for="product in items?.slice(0, 3)"
                    :key="product.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.slug },
                          })
                          setSelectedProduct(product)
                        }
                      "
                      @goToDetails="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.slug },
                          })
                          setSelectedProduct(product)
                        }
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="list_product mx-n3 pl-3" key="Sale">
                  <v-container fluid class="pa-0 mb-12">
                    <h4 class="mb-3">Sale</h4>
                    <v-row class="row sale_products mx-n2 mb-12">
                      <v-col sm="4" class="sale_banner px-2">
                        <img
                          :src="saleCategoryBanner[0].photo"
                          :alt="getImageName(saleCategoryBanner[0].photo)" :title="getImageName(saleCategoryBanner[0].photo)" 

                        />
                        <button
                          ><a :href="saleCategoryBanner[0].link">{{
                            saleCategoryBanner[0].ext_inf_2
                          }}</a></button
                        >
                      </v-col>
                      <v-col sm="8" class="recommended_products px-1">
                        <v-row class="ma-n2">
                          <v-col
                            v-for="product in items.slice(0, 4)"
                            :key="product.product.id"
                            sm="6"
                            cols="4"
                            class="p_items pa-2"
                          >
                            <ProductCard
                              :product="product?.product"
                              :price="product.price"
                              :currency="product.currency"
                              @click.native="
                                () => {
                                  $router.push({
                                    name: 'Product',
                                    params: { id: product.product.slug },
                                  })
                                  setSelectedProduct(product.product)
                                }
                              "
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                  <div class="mb-12 px-0">
                    <a :href="mainCategoryBanner1[0].link">
                      <img
                        :src="mainCategoryBanner1[0].photo"
                        class="d-block w-100 mb-5 pb-2"
                        :alt="getImageName(mainCategoryBanner1[0].photo)" :title="getImageName(mainCategoryBanner1[0].photo)" 

                      />
                    </a>
                  </div>

                  <BannersCarousel
                    title="Our collections"
                    :items="collections"
                  />
                  <v-container fluid class="pa-0">
                    <h4 class="online_s">Online Shopping Made Easy</h4>
                    <div class="first_banners">
                      <div
                        v-for="banner in mainCategoryBanners2"
                        :key="banner.id"
                        class="banner"
                      >
                        <div
                          class="banner__img"
                          :style="{
                            background: `url(${banner?.photo}) no-repeat`,
                            backgroundSize: 'cover',
                          }"
                        ></div>
                        <span class="banner__title">{{ banner.name }}</span>
                      </div>
                    </div>
                  </v-container>

                  <BannersCarousel
                    title="best sellers"
                    product
                    :items="bestSellers"
                    @selectItem="selectItem"
                    @addToCart="addToCart"
                  />
                </v-row>
              </transition-group>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import { getImageName } from '@/utils/utils'

import CarouselComponent from '@/view/content/store/CarouselComponent'
import BannersCarousel from '@/view/content/store/BannersCarousel'
import ProductCard from '@/view/content/store/ProductCard'
import CategoryRecursion from './CategoryRecursion.vue'
const { mapState: State_Auth } = createNamespacedHelpers('auth')
const { mapActions } = createNamespacedHelpers('storeCart')
const { mapState } = createNamespacedHelpers('storeProducts')
const { mapState: State_products, mapActions: Actions_products } =
  createNamespacedHelpers('products')
const { mapState: State_blogs, mapActions: Actions_blogs } =
  createNamespacedHelpers('blogs')
const { mapState: State_banners, mapActions: Actions_banners } =
  createNamespacedHelpers('banners')

const {
  mapState: State_reatailCategories,
  mapActions: Actions_reatailCategories,
  mapMutations: Mutations_retailCategories,
} = createNamespacedHelpers('retailCategories')
export default {
  name: 'CategoriesMain',
  components: {
    ProductCard,
    // CarouselComponent,
    BannersCarousel,
    CategoryRecursion,
  },
  data() {
    return {
      min: -50,
      max: 90,
      value: [-20, 70],
      sortedBy: 'Sort by popularity',
      colorFilterBy: '',
      items: [],
      baseUrl: '',
    }
  },
  computed: {
    ...State_reatailCategories([
      'retailCategories',
      'category',
      'defaultCategory',
    ]),
    ...State_banners(['allBanners']),
    ...mapState([
      'colors',
      'feltShoes',
      'interiorItems',
      'accessories',
      'recommendedItems',
      'bestSellers',
      'collections',
    ]),
    ...State_products(['allProducts', 'mainProducts']),
    ...State_Auth(['localeLang']),
    mainCategoryBanners() {
      return this.allBanners.filter((el) => el.code === 'main-category')
    },
    saleCategoryBanner() {
      return this.allBanners.filter((el) => el.code === 'sale-category')
    },
    mainCategoryBanner1() {
      return this.allBanners.filter(
        (el) => el.code === 'category-main-banner-1'
      )
    },
    mainCategoryBanners2() {
      const filtered = this.allBanners.filter(
        (el) => el.code === 'caterogry-main-banners2'
      )
      const rebuilt = filtered.map((i) => {
        return {
          ...i,
          photo: encodeURI(i.photo),
        }
      })
      return rebuilt
    },
    // ...State_blogs(['collections']),
  },
  watch: {
    allProducts(val) {
      this.items = val
    },
  },
  methods: {
    getImageName,

    ...mapActions(['toggleSideCart', 'addCartItem', 'setSelectedProduct']),
    ...Actions_products([
      'searchProducts',
      'getAllProducts',
      'productByCatigory',
      'getProductsMainPage',
    ]),
    ...Actions_banners(['fetchAllBanners']),
    ...Actions_blogs(['fetchBlogs']),
    ...Actions_reatailCategories([
      'getAllRetailCategories',
      'getRetailCategoryBySlug',
    ]),
    ...Mutations_retailCategories(['SET_DEFAULT_CATEGORY']),
    selectItem(item) {
      this.setSelectedProduct(item)
      this.$router.push({ name: 'Product', params: { id: item.id } })
    },
    addToCart(item) {
      const copy = cloneDeep(item)
      copy.colors = copy.colors[0]
      copy.colors.sizes = [copy.colors.sizes[0].value]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    filterByColor(id) {
      const color = this.colors.find((color) => color.id === id)
      this.colorFilterBy = color.title
    },
    setCategories(title, category, categoryId) {
      this.activeCategory = title
      this.$router.push({
        name: 'Categories',
        params: { url: category.url },
        query: { categoryId },
      })
      //   this.productByCatigory({ id: categoryId })
      //   this.selectedCategory = category
      //   this.$router.replace({
      //     query: { category: category.url },
      //   })
      //   this.categoryBanner = category
      //   this.SET_DEFAULT_CATEGORY(category)
    },
  },
  async created() {
    await this.getAllRetailCategories()
    await this.getAllProducts()
    // this.getAllProducts()
    this.baseUrl = this.$store.state.auth.API_URL
    await this.fetchBlogs(2)
    const per_page = 22
    await this.fetchAllBanners(per_page)
    // const currentCategory = this.$route.query.category
    // if (!currentCategory) {
    //   this.$router.replace({
    //     query: { category: this.retailCategories[0]?.url },
    //   })
    //   this.productByCatigory({ id: this.retailCategories[0]?.id })
    // }
    // const category = this.retailCategories.find(
    //   (item) => item.url === currentCategory
    // )
    // this.productByCatigory({ id: category?.id })
    // this.SET_DEFAULT_CATEGORY(category)
  },
}
</script>
<style lang="scss">
.products {
  overflow: visible;
}

// .sale_banner {
//   width: 30%;
//   height: 100%;
//   background: url('http://ovz2.j1146928.m6x5m.vps.myjino.ru/storage/filemanager/Home-page/slider/003%20(2).jpg');
// }
.first_banners {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .banner {
    width: 240px;
    height: 150px;
    background: none;
    &__img {
      width: 100%;
      height: 135px;
      // background-size: cover;
    }
    &__title {
      text-align: center;
      color: #666666;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}
.categories {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .v-slider__thumb-label {
    height: 10px !important;
    width: 10px !important;
    background-color: unset !important;
    color: black;
    border-color: unset !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 8px 8px 8px 16px;
  }
  .bordered {
    .v-badge__badge {
      border: 1px solid;
      border-color: #e1e1e1 !important;
    }
  }
  .sort {
    .v-btn__content {
      font-size: 0.875rem;
      color: #666666;
      font-weight: 500;
      padding: 2px 14px 2px 10px;
      letter-spacing: normal;
    }
  }
}
.sort__menu {
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  display: block;
}
.p_items {
  & > div {
    border: 1px solid #f2f2f2;
    .p_text {
      border-top: 1px solid #f2f2f2;
    }
  }
}
.v-btn {
  text-transform: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}
.v-expansion-panel-header {
  font-size: 0.875rem;
  color: #666666;
  font-weight: 500;
  padding-left: 8%;
  min-height: unset;
}
.filter_header {
  .f_items.active a {
    color: #666666;
    font-weight: 700;
  }
  .f_items {
    cursor: pointer !important;
  }
  .f_items a {
    font-weight: 500;
    height: 100%;
  }
}
.v-menu__content {
  background-color: #fff;
}
.size-filter {
  span {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
  }
}
.sort_by {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-right: 10px;
}
main.container.categories {
  padding-top: 0;
  margin-top: 0;
}
main.container.categories {
  margin-top: 84px !important;
}
.p17 {
  padding: 14px 0;
}
</style>
